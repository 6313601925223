import React, { useState, useEffect } from "react";
import {
  ChakraProvider,
  Box,
  Heading,
  theme,
  Center,
  Flex,
  Text,
  Button,
  Container,
  IconButton,
  Spinner,
  VStack,
  Card,
  CardHeader,
  CardBody,
  Tooltip,
  Icon,
  Progress,
} from "@chakra-ui/react";
import { ArrowBackIcon, ArrowForwardIcon, CheckIcon } from "@chakra-ui/icons";
import LoadingOverlay from "react-loading-overlay-ts";
import QuestionnaireManager, {
  Answer,
} from "../../../questionnaire/questionnaire-manager";
import { SurveyButton } from "./SurveyButton";
import { isMobile } from "react-device-detect";
import Footer from '../../../Footer';

interface PercentageProgressBarProps {
  progressValue: number;
}

function PercentageProgressBar(props: PercentageProgressBarProps) {
  return (
    <Flex
      width={"100%"}
      direction={"row"}
      flexGrow={1}
      alignSelf={"center"}
      marginBottom={"16px"}
      alignItems={"center"}
    >
      <Text color={"#bbbbbb"} fontSize={"16px"}>
        {props.progressValue}%
      </Text>
      <Progress
        width={"100%"}
        margin={"0rem 2rem"}
        borderRadius={"4px"}
        value={props.progressValue}
        bg={"gray.700"}
        colorScheme="green"
        justifyContent={"center"}
        justifySelf={"center"}
      />
      <Text
        color={"#bbbbbb"}
        fontSize={"16px"}
        textAlign={"center"}
        justifySelf={"center"}
      >
        100%
      </Text>
    </Flex>
  );
}

export interface QuestionData {
  Answers: string[];
  Is360Degree: boolean;
  Title: string;
  Subtitle: string | null;
  Id: string;
}

export interface QuestionDataResponse {
  Questions: QuestionData[];
  TeamMembers: string[];
  CandidateName: string;
}

interface SurveyBodyProps {
  onSurveyFinished: () => void;
}

function timeout(delay: number) {
  return new Promise((res) => setTimeout(res, delay));
}

function SurveyBody(props: SurveyBodyProps) {
  const [selectedSurveyButtonId, setSelectedSurveyButtonId] = useState("");
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);

  async function onClickCallback(id: string) {
    let proceed = selectedSurveyButtonId === "";
    QuestionnaireManager.SetAnswerForQuestionIndex(
      currentQuestionIndex,
      Number.parseInt(id.replace("survey-button-", ""))
    );
    setSelectedSurveyButtonId(id);

    await timeout(400);
    if (proceed && !QuestionnaireManager.IsLast()) getNextQuestion();
  }

  function isActive(id: string) {
    return id === selectedSurveyButtonId;
  }
  function getNextQuestion() {
    setSelectedSurveyButtonId("");
    setCurrentQuestionIndex(currentQuestionIndex + 1);
  }

  function getPreviousQuestion() {
    QuestionnaireManager.GoBackHappened();
    setSelectedSurveyButtonId("");
    setCurrentQuestionIndex(currentQuestionIndex - 1);
  }

  let question = QuestionnaireManager.GetQuestionByIndex(currentQuestionIndex);
  let buttons = question.buttonLabels.map((x) => ({ name: x }));
  let answerId =
    QuestionnaireManager.GetAnswerForQuestionIndex(currentQuestionIndex);
  if (answerId !== undefined) {
    let id = `survey-button-${answerId}`;
    if (selectedSurveyButtonId !== id) setSelectedSurveyButtonId(id);
  }

  let canGoBack = QuestionnaireManager.CanGoBack();
  let canGoForward = selectedSurveyButtonId.length !== 0;
  let isLast = QuestionnaireManager.IsLast() && canGoForward;
  let progressValue = QuestionnaireManager.ProgressValue();
  let hideHeader = buttons.length === 2;
  return (
    <>
      <Box marginTop={"2rem"}>
        <PercentageProgressBar progressValue={progressValue} />
      </Box>
      <Card
        padding={"1rem"}
        boxShadow={"none"}
        bg={"#35373f66"}
        borderLeft={"4px"}
        borderLeftColor={"#f56565"}
      >
        <CardHeader>
          <Box
            alignSelf="start"
            height={isMobile ? (hideHeader ? "3vh" : "25vh") : -1}
          >
            {!hideHeader && (
              <Heading
                mt={"1rem"}
                color="#3acad3"
                textAlign={"center"}
                size={"lg"}
                fontWeight={"semibold"}
                style={{
                  fontVariant: "small-caps",
                }}
              >
                To what extent do you agree with the following statement?
              </Heading>
            )}
            {!question.subtitle ? (
              <Heading
                size={isMobile ? "md" : "xl"}
                color="#eeeeee"
                textAlign={"center"}
                marginTop="1rem"
                fontWeight={"semibold"}
                height={"25%"}
              >
                {question.title}
              </Heading>
            ) : (
              <>
                <Heading
                  size={"xl"}
                  color="#444444"
                  textAlign={"left"}
                  marginTop="0.2rem"
                  fontWeight={"semibold"}
                >
                  {question.subtitle}
                </Heading>
                <Heading
                  size={"lg"}
                  color="#884444"
                  textAlign={"left"}
                  marginTop="0.2rem"
                  fontWeight={"normal"}
                  fontStyle={"italic"}
                >
                  "{question.title}"
                </Heading>
              </>
            )}
          </Box>
        </CardHeader>
        <CardBody>
          <Flex
            gap="1em"
            flexWrap="wrap"
            marginTop={"3rem"}
            direction={isMobile && hideHeader ? "column" : "row"}
          >
            {buttons.map(function (button, i) {
              let id = `survey-button-${i}`;
              return (
                <SurveyButton
                  title={button.name}
                  id={id}
                  onClick={() => onClickCallback(id)}
                  isActive={isActive(id)}
                />
              );
            })}
          </Flex>
          <Text
            style={{ visibility: isLast ? "visible" : "hidden" }}
            marginTop={"2rem"}
            fontSize="md"
            fontFamily={"sans-serif"}
            color="red.400"
            textAlign={"center"}
            marginBottom={"-1rem"}
          >
            By clicking the 'Finish' button, you will complete your assessment
            and submit your response.
          </Text>
        </CardBody>
      </Card>

      <Flex
        marginTop={"1rem"}
        direction="row"
        justifyContent="center"
        gap="2em"
      >
        <Button
          onClick={() => {
            if (!canGoBack) return;
            getPreviousQuestion();
          }}
          padding={"0rem 1rem"}
          variant="solid"
          bg={"gray.400"}
          size={"sm"}
          aria-label={""}
          width={"90px"}
        >
          Previous
        </Button>
        <Text fontSize="lg" color="#eeeeee" alignSelf={"center"}>
          {currentQuestionIndex + 1}
        </Text>
        <Tooltip label={canGoForward ? "" : "Select an answer to proceed"}>
          <Button
            onClick={() => {
              if (!canGoForward) return;
              isLast ? props.onSurveyFinished() : getNextQuestion();
            }}
            bg={"gray.400"}
            padding={"0rem 1rem"}
            variant="solid"
            size={"sm"}
            width={"90px"}
            aria-label={""}
          >
            {QuestionnaireManager.IsLast() ? "Finish" : "Next"}
          </Button>
        </Tooltip>
      </Flex>
    </>
  );
}

export interface SurveyPageProps {
  questionData: QuestionDataResponse;
  postData: (answer: SurveyAnswer) => void;
}

function SurveyPage(props: SurveyPageProps) {
  QuestionnaireManager.Initialize(props.questionData.Questions);

  return (
    <>
      <Flex
        width={isMobile ? "100%" : "80%"}
        height={"100%"}
        marginBottom={isMobile ? 0 : "10rem"}
        padding={isMobile ? "2rem" : 0}
        direction="column"
        alignSelf={"center"}
        justifyContent="center"
        flexGrow={"1"}
      >
        <SurveyBody
          onSurveyFinished={() => {
            let answers = QuestionnaireManager.GetAnswers();
            let goBackCount = QuestionnaireManager.GetGoBackCount();
            let surveyAnswer: SurveyAnswer = {
              metaData: {
                isMobile: isMobile,
                goBackCount: goBackCount
              },
              answers: answers,
            };

            props.postData(surveyAnswer);
          }}
        ></SurveyBody>
      </Flex>
    </>
  );
}

export interface SurveyAnswer {
  answers: Answer[];
  metaData: {
    goBackCount: number;
    isMobile: boolean;
  }
}

export default SurveyPage;
