import { createSlice } from '@reduxjs/toolkit'

export const accessCodeSlice = createSlice({
    name: 'accessCode',
    initialState: {
        value: '',
    },
    reducers: {
        set: (state, action) =>
        {
            state.value = action.payload;
        }
    },
})

// Action creators are generated for each case reducer function
export const { set } = accessCodeSlice.actions

export default accessCodeSlice.reducer