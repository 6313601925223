export interface GeneralQuestionsAnswer
{
    CompanyFounded: number;
    RoleInTheCompany: string;
    IE: number;
    SE: boolean;
    WorksMost: string;
    ThreatsOrdered: string[];
    FoundersKnownMatrix: any;
    FoundersOverallCommitmentHours: number;
    FoundersOverallCommitmentSatisfaction: string;
    FoundersCommitmentMatrix: any;
    FinancialSituationOrdered: string[];
    FinancialSituationComment: string;
    KnowledgeAboutCustomers: number;
    KnowledgeAboutCustomersBasedOn: string[] | null;
    KnowledgeAboutCustomersBasedOnComment: string;
    LongTermVision: string;
    LongTermVisionComment: string;
    LongTermVisionDurationYears: number;
    RichVsKingStructure: string;
    RichVsKingLean: string;
    RichVsKingHiring: string;
    RichVsKingGrowth: string;
    RichVsKingGrowthPace: string;
    RichVsKingGoal: string;
    FounderSkills: any;
}

export type CS = { value: string; choice: string };
export const commitmentSatisfaction: CS[] = [];
commitmentSatisfaction.push({ value: "N", choice: "I'm satisfied with it" });
commitmentSatisfaction.push({ value: "I", choice: "I want to increase it" });
commitmentSatisfaction.push({ value: "D", choice: "I want to decrease it" });

export type LV = { value: string; choice: string };
export const longTermVision: LV[] = [];
longTermVision.push({ value: "ACQUISITION", choice: "Acquisition" });
longTermVision.push({
    value: "IPO",
    choice: "IPO",
});
longTermVision.push({
    value: "STAYPRIVATE",
    choice: "Stay private",
});

export type FS = { value: string; choice: string };
export const financialSituation: FS[] = [];
financialSituation.push({
    value: "BOOTSTRAP",
    choice: "Bootstrapping (founder’s own money)",
});
financialSituation.push({ value: "GRANT", choice: "Grant" });
financialSituation.push({ value: "PRIZE", choice: "Prize" });
financialSituation.push({ value: "REVENUE", choice: "Revenue" });
financialSituation.push({
    value: "FFF",
    choice: "FFF (Family, Friends and Fools)",
});
financialSituation.push({
    value: "EXTERNALINVESTMENT",
    choice: "External investment (other than FFF) such as venture capital and private equity",
});
financialSituation.push({
    value: "STRATEGICPARTNERSHIP",
    choice: "Strategic Partnership or Corporate Investors",
});
financialSituation.push({
    value: "CROWDFUNDING",
    choice: "Crowdfunding",
});
financialSituation.push({
    value: "LOAN",
    choice: "Loan",
});

export type CK = { value: string; choice: string };
export const customerKnowledge: FS[] = [];
customerKnowledge.push({
    value: "CUSTINTERVIEWS",
    choice: "Customer interviews",
});
customerKnowledge.push({ value: "MARKETRES", choice: "Market research" });
customerKnowledge.push({ value: "INDUSTRYME", choice: "I'm coming from the industry" });
customerKnowledge.push({ value: "INDUSTRYFOUNDER", choice: "One of our team mates is from the industry" });

export function createGeneralQuestionsAnswerFromSurveyData(data: any)
{
    let ans: GeneralQuestionsAnswer = {
        CompanyFounded: data["company-founded"],
        RoleInTheCompany: data["role-in-the-company"],
        IE: data["industry-experience"],
        SE: data["serial-ent"] === "Yes",
        WorksMost: data["most-work-with"],
        ThreatsOrdered: data["company-threats-rank"],
        FoundersKnownMatrix: data["founder-known-matrix"],
        FoundersOverallCommitmentHours: data["overall-commitment-hours"],
        FoundersOverallCommitmentSatisfaction: commitmentSatisfaction.find(x => x.choice === data["overall-commitment-satisfaction"])?.value ?? "null",
        FoundersCommitmentMatrix: data["commitment-matrix"],
        FinancialSituationOrdered: data["financial-situation-rank"],
        FinancialSituationComment: data["financial-situation-Comment"],
        // FinancialSituationOrdered: (data["financial-situation"] as string[]).map(finSit => financialSituation.find(x => x.choice === finSit)?.value ?? "null"),
        KnowledgeAboutCustomers: data["customers-rating"],
        KnowledgeAboutCustomersBasedOn: data["customers-rating"] > 0 ? (data["customers-basement"] as string[]).filter(cusBasement => cusBasement != "other").map(cusBasement => customerKnowledge.find(x => x.choice === cusBasement)?.value ?? "null") : null,
        KnowledgeAboutCustomersBasedOnComment: data["customers-basement-Comment"] ?? "",
        LongTermVision: longTermVision.find(x => x.choice === data["longterm-vision"])?.value ?? "null",
        LongTermVisionComment: data["longterm-vision-Comment"],
        LongTermVisionDurationYears: data["longterm-vision-duration"],
        RichVsKingStructure: data["structure"],
        RichVsKingLean: data["lean"],
        RichVsKingHiring: data["hiring"],
        RichVsKingGrowth: data["growth"],
        RichVsKingGrowthPace: data["growth-pace"],
        RichVsKingGoal: data["goal"],
        FounderSkills: data["skills"]
    }

    return ans;
}