import { SurveyState } from "./TestPage";

export function getNextState(currentState: SurveyState): SurveyState
{
    switch (currentState)
    {
        case SurveyState.Welcome:
            return SurveyState.GeneralQuestionsIntroduction;
        case SurveyState.GeneralQuestionsIntroduction:
            return SurveyState.GeneralQuestions;
        case SurveyState.GeneralQuestions:
            return SurveyState.Break1;
        case SurveyState.Break1:
            return SurveyState.SurveyIntroduction;
        case SurveyState.SurveyIntroduction:
            return SurveyState.Survey;
        case SurveyState.Survey:
            return SurveyState.Break2;
        case SurveyState.Break2:
            return SurveyState._360Introduction;
        case SurveyState._360Introduction:
            return SurveyState._360;
        case SurveyState._360:
            return SurveyState.Finish;

        default:
            return SurveyState.Unknown;
    }
}

export function getNextStateSolo(currentState: SurveyState): SurveyState
{
    switch (currentState)
    {
        case SurveyState.Welcome:
            return SurveyState.Survey;
        case SurveyState.Survey:
            return SurveyState.Finish;

        default:
            return SurveyState.Unknown;
    }
}