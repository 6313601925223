import { Flex, Progress, Text } from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";
import "survey-core/defaultV2.min.css";
import { Model } from "survey-core";
import {
  ReactElementFactory,
  ReactSurveyElement,
  Survey,
} from "survey-react-ui";
import { themeJson } from "../theme";
import "./GeneralQuestionsPage.css";
import { Skill } from "../../../models/skill";
import SkillSetting from "../../../components/skill-setting/SkillSettings";
import { isMobile } from "react-device-detect";
import {
  GeneralQuestionsAnswer,
  createGeneralQuestionsAnswerFromSurveyData,
} from "../../../models/general-questions-answer";
import { getSurveyJson } from "./survey-json";
import React from "react";
import { LoadingComponent } from "../../../components/LoadingComponent";

export interface GeneralQuestionsDataResponse {
  TeamMembers: string[];
  Name: string;
}

export interface GeneralQuestionsPageProps {
  questionData: GeneralQuestionsDataResponse;
  postData: (answers: GeneralQuestionsAnswer) => void;
}

class PercentageProgressBar extends ReactSurveyElement {
  render() {
    return (
      <Flex
        width={"100%"}
        direction={"row"}
        flexGrow={1}
        alignSelf={"center"}
        marginBottom={"16px"}
        alignItems={"center"}
      >
        <Text color={"#bbbbbb"} fontSize={"16px"}>
          {this.props.model.progressValue}%
        </Text>
        <Progress
          width={"100%"}
          margin={"0rem 2rem"}
          borderRadius={"4px"}
          value={this.props.model.progressValue}
          bg={"gray.700"}
          colorScheme="green"
          justifyContent={"center"}
          justifySelf={"center"}
        />
        <Text
          color={"#bbbbbb"}
          fontSize={"16px"}
          textAlign={"center"}
          justifySelf={"center"}
        >
          100%
        </Text>
      </Flex>
    );
  }
}

ReactElementFactory.Instance.registerElement(
  "sv-progressbar-percentage",
  (props) => {
    return React.createElement(PercentageProgressBar, props);
  }
);

function GeneralQuestionsPage(props: GeneralQuestionsPageProps) {
  return (
    <GeneralQuestionsBody
      questionData={props.questionData}
      postData={props.postData}
    />
  );
}

function GeneralQuestionsBody(props: GeneralQuestionsBodyProps) {
  const targetDivRef = useRef<HTMLDivElement>(null);

  const surveyJson = getSurveyJson(props.questionData.TeamMembers, isMobile);
  const survey = new Model(surveyJson);
  const [finished, setFinished] = useState(false);

  survey.onAfterRenderSurvey.add(() => {
    survey.addLayoutElement({
      id: "progressbar-percentage",
      component: "sv-progressbar-percentage",
      container: "contentTop",
      data: survey,
    });
  });

  survey.applyTheme(themeJson);

  survey.onCurrentPageChanged.add(() => {
    document.getElementById("sv-nav-prev")!.style.display = "none";
    if (survey.currentPageNo === 1) removeSkillFromSurvey();
    survey.focusFirstQuestion();
  });

  survey.scrollToTopOnPageChange(true);

  survey.onComplete.add((sender, options) => {
    let data = sender.data;
    data["skills"] = skills
      .filter((x) => x.value > 0)
      .map((x) => ({ id: x.id, value: x.value }));

    let answers = createGeneralQuestionsAnswerFromSurveyData(data);
    setFinished(true);
    props.postData(answers);
  });

  const addSkillToSurvey = () => {
    if (survey.currentPageNo != 0) return;
    // Find the target <div> element by some unique identifier or criteria
    const targetDiv = document.querySelector(".sd-body__page");
    // Append your CustomComponent to the target <div> element
    if (targetDiv && targetDivRef.current) {
      let contains = targetDiv?.contains(targetDivRef.current);
      if (true) targetDiv.appendChild(targetDivRef.current);
    }
  };

  const removeSkillFromSurvey = () => {
    if (survey.currentPageNo === 0) return;
    // Find the target <div> element by some unique identifier or criteria
    const targetDiv = document.querySelector(".sd-body__page");
    // Append your CustomComponent to the target <div> element
    if (targetDiv && targetDivRef.current) {
      targetDiv.removeChild(targetDivRef.current);
    }
  };

  useEffect(() => {
    // Clicking on the next button
    document.getElementById("sv-nav-next")!.onclick = (ev) => {
      if (survey.currentPageNo !== 0) return;
      // Only the 0th page
      let skillPoints = 0;
      skills.forEach((x) => (skillPoints += x.value));

      // Skill alert set to invisible
      document.getElementById("skill-setting-alert")!.style.display = "none";
      if (skillPoints !== 20) {
        // If everything else is fine, stop propagation, so we won't go to the next page, in this case we need to scroll to the skill setting component.
        // Otherwise the scrolling is handled by the surveyJS library.
        if (survey.isCurrentPageValid) {
          ev.preventDefault();
          ev.stopPropagation();
          ev.stopImmediatePropagation();

          targetDivRef.current?.scrollIntoView();
        }

        // Set the alert to visible
        document.getElementById("skill-setting-alert")!.style.display = "block";
      }
    };

    addSkillToSurvey();
  }, [props.questionData]);

  let skills: Skill[] = [
    { value: 0, label: "Product Development", id: "prod-dev" },
    { value: 0, label: "Marketing", id: "marketing" },
    { value: 0, label: "Sales", id: "bus-dev" },
    { value: 0, label: "Legal", id: "legal" },
    { value: 0, label: "Finance", id: "finance" },
    { value: 0, label: "Domain Knowledge", id: "domain-knowledge" },
  ];

  const changeSkillValue = (i: number, val: number) => {
    skills[i].value = val;
  };

  if (finished) {
    return <LoadingComponent headerText="Loading..." />;
  }

  return (
    <Flex
      width={"100%"}
      direction="column"
      alignSelf={"center"}
      alignItems={"center"}
      justifyContent="start"
      overflowY={"auto"}
    >
      <Survey model={survey} />
      <div
        className="sd-row sd-clearfix sd-page__row skill-setting"
        ref={targetDivRef}
      >
        <div
          style={{
            flex: "1 1 100%",
            minWidth: "300px",
            maxWidth: "100%",
          }}
        >
          <div className="sd-element--with-frame sd-element sd-question sd-row__question sd-question--boolean">
            <div
              className="sd-question__erbox sd-question__erbox--outside-question sd-question__erbox--above-question"
              id="skill-setting-alert"
              style={{ display: "none" }}
            >
              <div>
                <span className="sv-string-viewer">
                  All 20 points must be distributed.
                </span>
              </div>
            </div>
            <SkillSetting skills={skills} changeSkillValue={changeSkillValue} />
          </div>
        </div>
      </div>
    </Flex>
  );
}

interface GeneralQuestionsBodyProps {
  questionData: GeneralQuestionsDataResponse;
  postData: (answers: GeneralQuestionsAnswer) => void;
}

export default GeneralQuestionsPage;
