import { Card, Text, Spinner, VStack } from "@chakra-ui/react";
import { isMobile } from "react-device-detect";

export interface Props {
  headerText?: string;
}
export function LoadingComponent(props: Props) {
  return (
    <VStack h={"80%"} justifyContent={"center"}>
      <Card
        alignItems={"center"}
        justifySelf={"center"}
        padding={"3rem"}
        bg={"whiteAlpha.800"}
        w={isMobile ? "80%" : "20%"}
      >
        <Spinner
          size="xl"
          thickness="4px"
          emptyColor="gray.200"
          color="teal.400"
          speed="2.5s"
        />
        <Text color="black.400" paddingTop="1rem">
          {props.headerText ?? "Acquiring data..."}
        </Text>
      </Card>
    </VStack>
  );
}
