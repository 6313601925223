import { Model, Serializer } from "survey-core";
import {
  ReactElementFactory,
  ReactSurveyElement,
  Survey,
} from "survey-react-ui";
import { themeJson } from "../theme";
import "./360Page.css";
import { isMobile } from "react-device-detect";
import { Flex, Progress, Text } from "@chakra-ui/react";
import { QuestionDataResponse } from "../survey/SurveyPage";
import React from "react";
import {
  _360QuestionsAnswer,
  create360QuestionsAnswerFromSurveyData,
} from "../../../models/360-questions-answer";

Serializer.addProperty("survey", "progressTitle");

class PercentageProgressBar extends ReactSurveyElement {
  render() {
    return (
      <Flex
        width={"100%"}
        direction={"row"}
        flexGrow={1}
        alignSelf={"center"}
        marginBottom={"16px"}
        alignItems={"center"}
      >
        <Text color={"#bbbbbb"} fontSize={"16px"}>
          {this.props.model.progressValue}%
        </Text>
        <Progress
          width={"100%"}
          margin={"0rem 2rem"}
          borderRadius={"4px"}
          value={this.props.model.progressValue}
          bg={"gray.700"}
          colorScheme="green"
          justifyContent={"center"}
          justifySelf={"center"}
        />
        <Text
          color={"#bbbbbb"}
          fontSize={"16px"}
          textAlign={"center"}
          justifySelf={"center"}
        >
          100%
        </Text>
      </Flex>
    );
  }
}

ReactElementFactory.Instance.registerElement(
  "sv-progressbar-percentage",
  (props) => {
    return React.createElement(PercentageProgressBar, props);
  }
);

export interface _360PageProps {
  questionData: QuestionDataResponse;
  postData: (data: _360QuestionsAnswer) => void;
}

function _360Page(props: _360PageProps) {
  const json = {
    showCompletedPage: false,
    goNextPageAutomatic: true,
    showPrevButton: true,
    pages: getAllElements(props.questionData),
    showQuestionNumbers: "off",
    widthMode: "static",
    width: isMobile ? "100%" : "75%",
  };

  const survey = new Model(json);

  if (isMobile) {
    survey.onValueChanged.add((sender: Model, options) => {
      let filledRowsLength = Object.keys(options.value).length;
      if (filledRowsLength === props.questionData.TeamMembers.length) {
        if (!survey.isLastPage) survey.nextPage();
        else survey.completeLastPage();
      }
      // scrollToNextRow(teamMemberName);
    });
  }

  survey.showNavigationButtons = true;
  survey.onComplete.add(() => {
    const answer = create360QuestionsAnswerFromSurveyData(survey.data);
    props.postData(answer);
  });
  survey.onAfterRenderSurvey.add(() => {
    survey.addLayoutElement({
      id: "progressbar-percentage",
      component: "sv-progressbar-percentage",
      container: "contentTop",
      data: survey,
    });
  });

  let allowNextPage = true;
  survey.onCurrentPageChanging.add(async (sender, options) => {
    if (!allowNextPage) return;
    options.allow = false;
    setTimeout(function () {
      allowNextPage = false;
      survey.currentPage = options.newCurrentPage;
      allowNextPage = true;
    }, 25);
  });

  survey.applyTheme(themeJson);
  return (
    <Flex
      className="third-section-root"
      alignItems={"center"}
      flexGrow={1}
      marginBottom={"5rem"}
    >
      <Survey model={survey} />
    </Flex>
  );
}

export default _360Page;
function getAllElements(questionData: QuestionDataResponse): any {
  let questions = questionData.Questions;
  let k = questions.map((x, i) => ({
    elements: [
      {
        type: isMobile ? "matrixdropdown" : "matrix",
        name: "question_" + x.Id,
        title: x.Title,
        description:
          isMobile && x.Answers.length != 2
            ? "What do you think your teammates would answer to the question? (1 - completely disagree    to    6 - completely agree)"
            : "What do you think your teammates would answer to the question?",
        columns: isMobile
          ? [
              {
                choices:
                  x.Answers.length === 6
                    ? ["1", "2", "3", "4", "5", "6"].map((x, j) => ({
                        value: j,
                        text: x,
                      }))
                    : x.Answers.map((x, i) => ({ value: i, text: x })),
                cellType: "radiogroup",
                isRequired: true,
              },
            ]
          : x.Answers.map((x, i) => ({ value: i, text: x })),
        rows: questionData.TeamMembers.map((x) => ({
          value: x,
          text: x.substring(0, 25),
        })),
        alternateRows: isMobile ? false : true,
        isAllRowRequired: true,
      },
    ],
  }));

  return k;
}
