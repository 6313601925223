import { useState } from "react";
import { useCheckMobileScreen } from "../../useCheckMobileScreen";
import {
  Grid,
  GridItem,
  Slider,
  SliderTrack,
  Box,
  SliderFilledTrack,
  SliderThumb,
  IconButton,
  Text,
} from "@chakra-ui/react";
import { AddIcon, MinusIcon } from "@chakra-ui/icons";
import React from "react";

interface MySliderProps {
  skillLabel: string;
  pointsDistributed: number;
  sliderValueChanged: (oldValue: number, newValue: number) => void;
}

function MySlider(props: MySliderProps) {
  const [value, setValue] = useState(0);
  const isMobile = useCheckMobileScreen();

  const handleSliderChange = (newValue: number) => {
    if (newValue < 0 || newValue > 10) return;
    if (props.pointsDistributed + newValue - value > 20) {
      // Increase is being attempted, handle accordingly
      // Add your custom logic here
      // For example, you can disable the increase by not updating the state
      return;
    }

    props.sliderValueChanged(value, newValue);
    setValue(newValue);
  };

  const getColor = () => {
    return value > 0 ? "white" : "#998888bb";
  };

  return (
    <Grid
      templateRows="repeat(2, 1fr)"
      templateColumns="repeat(3, 1fr)"
      justifyItems={"space-between"}
    >
      <GridItem rowSpan={1} colSpan={1} alignSelf={"end"}>
        <Text
          fontSize={"sm"}
          fontWeight={"normal"}
          color={getColor()}
          flex={1}
          marginBottom={"-1rem"}
        >
          {props.skillLabel}
        </Text>
      </GridItem>
      <GridItem colSpan={1} />
      <GridItem colSpan={1} alignSelf={"end"} justifySelf={"end"}>
        <Text
          fontSize={"sm"}
          fontWeight={"normal"}
          color={getColor()}
          flex={1}
          marginBottom={"-1rem"}
        >
          {value}
        </Text>
      </GridItem>
      <GridItem colSpan={3}>
        <Slider
          value={value}
          min={0}
          max={10}
          flex={4}
          mt={"1rem"}
          orientation="horizontal"
          onChange={handleSliderChange}
        >
          <SliderTrack bg={getColor()}>
            <Box position="relative" />
            <SliderFilledTrack bg="#f56565" />
          </SliderTrack>
          <SliderThumb boxSize={6} color={getColor()} />
        </Slider>
        <IconButton
          marginTop={"-1rem"}
          marginLeft={"1.5rem"}
          icon={<MinusIcon />}
          color={"red"}
          disabled={value === 0}
          size={isMobile ? "xs" : "sm"}
          onClick={() => handleSliderChange(value - 1)}
          aria-label={"minus-skill-point"}
        />
        <IconButton
          marginTop={"-1rem"}
          marginLeft={"1rem"}
          icon={<AddIcon />}
          disabled={props.pointsDistributed === 20 || value === 10}
          size={isMobile ? "xs" : "sm"}
          onClick={() => handleSliderChange(value + 1)}
          color={"green"}
          aria-label={"add-skill-point"}
        />
      </GridItem>
    </Grid>
  );
}

export const SkillSlider = React.memo(MySlider);
