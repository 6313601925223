import {
  Box,
  Card,
  CardBody,
  CardHeader,
  Flex,
  Heading,
  Link,
  Text,
} from "@chakra-ui/react";
import { isMobile } from "react-device-detect";

function FinishPage() {
  return (
    <Flex
      flexGrow={1}
      justifyContent={"center"}
      alignItems={isMobile ? "center" : "start"}
      margin={isMobile ? "1rem" : "0rem"}
      marginBottom={isMobile ? "4rem" : "0rem"}
    >
      <Card
        boxShadow={"none"}
        bg={"#35373f"}
        justifySelf={"center"}
        height={"50%"}
        marginTop={isMobile ? "0" : "4rem"}
        width={isMobile ? "100" : "70%"}
        borderLeft={"4px"}
        borderLeftColor={"#f56565"}
        paddingBottom={"1rem"}
      >
        <CardHeader>
          <Box alignSelf="start">
            <Heading
              mt={"1rem"}
              color="#3acad3"
              textAlign={"center"}
              size={"lg"}
              fontWeight={"semibold"}
              style={{
                fontVariant: "small-caps",
              }}
            >
              Finished
            </Heading>
          </Box>
        </CardHeader>
        <CardBody>
          <Text
            fontSize={isMobile ? "md" : "2xl"}
            fontFamily={"sans-serif"}
            color="#bbbbbb"
            textAlign={"center"}
            whiteSpace={"pre-line"}
          >
            Congratulations! The test has been successfully completed. You will
            soon receive a detailed report of your results, along with an
            in-depth analysis.
          </Text>
        </CardBody>
      </Card>
    </Flex>
  );
}

export default FinishPage;
