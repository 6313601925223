import React, { useState, useEffect } from "react";
import { Box, Button, Card, Text } from "@chakra-ui/react";
import { useCheckMobileScreen } from "../../../useCheckMobileScreen";

export interface Props {
  id: string;
  onClick: () => void;
  isActive: boolean;
  title: string;
}

export function SurveyButton(props: Props) {
  const isMobile = useCheckMobileScreen();
  return (
    <Card
      flex={1}
      boxShadow={"xl"}
      variant={"elevated"}
      bg={"teal"}
      minWidth={isMobile ? "25vw" : 0}
      maxWidth={isMobile ? "65vw" : -1}
    >
      <Button
        id={props.id}
        onClick={props.onClick}
        isActive={props.isActive}
        _hover={{
          bg: isMobile ? "teal" : "#ff002244",
        }}
        _active={{
          bg: "#ff006644",
        }}
        style={{
          fontVariant: "small-caps",
          whiteSpace: "normal",
          wordWrap: "break-word",
        }}
        fontWeight="normal"
        fontSize={isMobile ? "0.8rem" : "calc(1.0vw)"}
        color={"#eeeeee"}
        minHeight="70px"
        bg={"transparent"}
      >
        {props.title}
      </Button>
    </Card>
  );
}
