import { QuestionData } from "../pages/test-page/survey/SurveyPage";

export interface Question
{
    id: string;
    title: string;
    subtitle: string | null;
    buttonLabels: string[];
}

class QuestionnaireManager
{
    static questions: Question[];
    static answers: { [key: number]: number } = {};
    static currentIndex = 0;
    static maxIndexReached = 0;
    static goBackCount = 0;

    static Initialize(questionData: QuestionData[])
    {
        this.questions = [];
        try
        {
            for (const question of questionData)
            {
                this.questions.push({
                    id: question.Id,
                    subtitle: question.Subtitle,
                    title: question.Title,
                    buttonLabels: question.Answers
                });
            }
        } catch (error)
        {
        }
    }

    static IsLast(): boolean
    {
        return this.currentIndex === this.questions.length - 1;
    }

    static CanGoBack(): boolean
    {
        if (this.currentIndex === 0)
            return false;

        return true;
    }

    static GetQuestionByIndex(index: number)
    {
        this.currentIndex = index;
        if (index > this.maxIndexReached)
            this.maxIndexReached = index;

        return this.questions[index];
    }

    static GoBackHappened() 
    {
        this.goBackCount++;
    }

    static SetAnswerForQuestionIndex(questionIndex: number, answerIndex: number)
    {
        this.answers[questionIndex] = answerIndex;
    }

    static GetAnswerForQuestionIndex(questionIndex: number)
    {
        return this.answers[questionIndex];
    }

    static MaxQuestionsCount()
    {
        return this.questions.length;
    }

    static ProgressValue()
    {
        return Math.round(100 * (this.maxIndexReached) / this.questions.length);
    }

    static GetAnswers(): Answer[]
    {
        let r: Answer[] = [];
        for (let index = 0; index < this.questions.length; index++)
        {
            const element = this.questions[index];
            const answerIndex = this.answers[index];

            r.push({ Id: element.id, answerIndex: answerIndex })
        }

        return r;
    }

    static GetGoBackCount() : number 
    {
        return this.goBackCount;
    }
}

export interface Answer
{
    Id: string;
    answerIndex: number;
}

export default QuestionnaireManager;