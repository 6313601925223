import {
  Box,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Flex,
  Heading,
  Text,
} from "@chakra-ui/react";
import { isMobile } from "react-device-detect";

export interface SectionIntroductionPageProps {
  onNextClicked: () => void;
  text: string;
  header: string;
}
function SectionIntroductionPage(props: SectionIntroductionPageProps) {
  return (
    <Flex
      flexGrow={1}
      justifyContent={"center"}
      alignItems={isMobile ? "center" : "start"}
      margin={isMobile ? "1rem" : "0rem"}
      marginBottom={isMobile ? "4rem" : "0rem"}
    >
      <Card
        boxShadow={"none"}
        bg={"#35373f"}
        justifySelf={"center"}
        height={"50%"}
        marginTop={isMobile ? "0" : "4rem"}
        width={isMobile ? "100" : "70%"}
        borderLeft={"4px"}
        borderLeftColor={"#f56565"}
      >
        <CardHeader>
          <Box alignSelf="start">
            <Heading
              mt={"1rem"}
              color="#3acad3"
              textAlign={"center"}
              size={"lg"}
              fontWeight={"semibold"}
              style={{
                fontVariant: "small-caps",
              }}
            >
              {props.header}
            </Heading>
          </Box>
        </CardHeader>
        <CardBody>
          <Text
            fontSize={isMobile ? "md" : "2xl"}
            fontFamily={"sans-serif"}
            color="#bbbbbb"
            textAlign={"center"}
            whiteSpace={"pre-line"}
          >
            {props.text}
          </Text>
        </CardBody>
        <CardFooter justifyContent={"center"}>
          <Button
            justifyContent={"center"}
            padding={"0rem 2rem"}
            size={isMobile ? "md" : "lg"}
            fontSize={isMobile ? "md" : "lg"}
            onClick={props.onNextClicked}
          >
            Next
          </Button>
        </CardFooter>
      </Card>
    </Flex>
  );
}

export default SectionIntroductionPage;
