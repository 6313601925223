import { useEffect, useState } from "react";
import axios, { AxiosError, AxiosRequestConfig } from "axios";

export enum APICallState
{
    Loading,
    Loaded,
}

export enum APICallResult
{
    Unknown,
    Ok,
    Error,
    Forbidden
}

export interface PostResult
{
    callState: APICallState
    callResult: APICallResult,
    data: any
}

async function post(url: string, data: any, config: AxiosRequestConfig): Promise<PostResult>
{
    try
    {
        let response = await axios.post(url, data, config);
        return { callState: APICallState.Loaded, callResult: APICallResult.Ok, data: response.data }
    } catch (err)
    {
        // let status: number = err.response.status;
        // if (status === 403)
        //     return { callState: APICallState.Loaded, callResult: APICallResult.Forbidden, data: null }
        // else
        return { callState: APICallState.Loaded, callResult: APICallResult.Error, data: null }
    }
}

export default post;