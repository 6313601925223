import { useState } from "react";
import { useCheckMobileScreen } from "../../useCheckMobileScreen";
import { Flex } from "@chakra-ui/react";
import { SkillSlider } from "./SkillSlider";
import { Skill } from "../../models/skill";

export interface SkillSettingProps {
  skills: Skill[];
  changeSkillValue(index: number, value: number): void;
}

function SkillSetting(props: SkillSettingProps) {
  const [pointsDistributed, setPointsDistributed] = useState(0);
  const isMobile = useCheckMobileScreen();

  const sliderValueChanged = (oldValue: number, newValue: number) => {
    const points = pointsDistributed - oldValue + newValue;
    setPointsDistributed(points);
  };

  return (
    <Flex direction="column" width={"100%"}>
      <div className="sd-question__header sd-element__header sd-question__header--location-top sd-element__header--location-top">
        <h5 className="sd-title sd-element__title sd-question__title sd-question__title--required">
          <span className="sv-string-viewer">
            In this section, please allocate 20 points between your skills
            below.
          </span>
        </h5>
      </div>
      <div className="sd-question__header sd-element__header sd-question__header--location-top sd-element__header--location-top">
        <h5 className="sd-description sd-question__description">
          <span className="sv-string-viewer">
            Remaining points: {20 - pointsDistributed}
          </span>
        </h5>
      </div>
      <Flex
        h={"40%"}
        w={isMobile ? "80%" : "40%"}
        padding={isMobile ? "0rem 1rem" : "0"}
        direction={"column"}
      >
        {props.skills.map((skill, i) => {
          return (
            <SkillSlider
              skillLabel={skill.label}
              pointsDistributed={pointsDistributed}
              sliderValueChanged={(oldVal: number, newVal: number) => {
                sliderValueChanged(oldVal, newVal);
                props.changeSkillValue(i, newVal);
              }}
            ></SkillSlider>
          );
        })}
      </Flex>
    </Flex>
  );
}

export default SkillSetting;
