import {
  Box,
  Button,
  Flex,
  Heading,
  Icon,
  IconButton,
  Image,
  Stack,
  Text,
} from "@chakra-ui/react";
import {
  IoIosPeople,
  IoIosKey,
  IoIosPerson,
  IoIosAnalytics,
  IoMdAnalytics,
} from "react-icons/io";
import {
  RiInformationFill,
  RiInformationLine,
  RiSurveyLine,
} from "react-icons/ri";
import { MdOutlineTouchApp } from "react-icons/md";
import { RiShieldKeyholeFill } from "react-icons/ri";
import { GiPlayButton } from "react-icons/gi";
import { useLocation, useNavigate } from "react-router-dom";
import { useCheckMobileScreen } from "../../useCheckMobileScreen";
import IconComponent from "../../components/IconComponents";
import { useCallback, useEffect, useState } from "react";
import TextTransition, { presets } from "react-text-transition";
import { CheckIcon, EmailIcon } from "@chakra-ui/icons";
import "./LandingPage.css";

function LandingPage() {
  const TEXTS = ["Measure", "Evaluate", "Improve"];

  const [index, setIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(
      () => setIndex((index) => index + 1),
      2000 // every 3 seconds
    );
    return () => clearTimeout(intervalId);
  }, []);

  const [code, setCode] = useState("");
  const location = useLocation();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const codeParam = queryParams.get("code");
    if (codeParam) {
      setCode(codeParam);
    }
  }, [location]);

  const getSwappingTextColor = useCallback(() => {
    let remainder = index % TEXTS.length;
    if (remainder === 0) return "orange";
    if (remainder === 1) return "#f56565";
    if (remainder === 2) return "green.400";
  }, [index]);

  const navigateToCompanyLanding = () => {
    window.location.href = "https://www.eta-technologies.com";
  };

  const navigate = useNavigate();
  const isMobile = useCheckMobileScreen();
  return (
    <Flex
      direction={isMobile ? "column-reverse" : "row"}
      alignItems={"center"}
      alignSelf={"center"}
      justifyContent={"space-between"}
      marginBottom={"1rem"}
      height={"100%"}
      w={isMobile ? "100%" : "65%"}
    >
      <Flex direction={"column"} marginLeft={"2rem"}>
        <Stack direction={"column"}>
          <Heading
            color={getSwappingTextColor()}
            fontSize={"3em"}
            fontFamily={"ui-monospace"}
          >
            <TextTransition springConfig={presets.slow} style={{ margin: 0 }}>
              {TEXTS[index % TEXTS.length]}
            </TextTransition>
          </Heading>
          <Heading
            color={"#cccccc"}
            fontFamily={"ui-monospace"}
            fontSize={"3em"}
          >
            Your Team
          </Heading>
          <Text
            color={"#cccccc"}
            fontFamily={"ui-monospace"}
            width={"80%"}
            paddingLeft={"0.5rem"}
            paddingTop={"0.5rem"}
            fontSize={"0.8em"}
          >
            ETA tells you in which teams you should invest. <br /> At pre-seed
            stage the number of measurable assets are limited, thus investors
            need to focus on the team and chemistry among the members.
          </Text>
          <Stack
            direction={"row"}
            gap={"1rem"}
            paddingTop={"1rem"}
            paddingLeft={"0.5rem"}
          >
            <Button
              leftIcon={<CheckIcon />}
              onClick={() => navigate("access-code", {state: { accessCode: code }})}
              size={isMobile ? "sm" : "lg"}
              colorScheme="teal"
            >
              Take the test
            </Button>
            <Button
              leftIcon={<RiInformationLine />}
              size={isMobile ? "sm" : "lg"}
              onClick={navigateToCompanyLanding}
            >
              More information
            </Button>
          </Stack>
        </Stack>
      </Flex>
      <Image
        src="/eta-now-image.png"
        borderRadius={"full"}
        boxSize={isMobile ? "75%" : "-1px"}
        objectFit={"cover"}
      />
    </Flex>
  );
}

export default LandingPage;
