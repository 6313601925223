import { isMobile } from "react-device-detect";

export interface _360QuestionsAnswer
{
    answers: _360QuestionAnswer[];
}

interface _360QuestionAnswer
{
    questionId: string;
    name: string;
    value: number;
}

interface ParsedObject
{
    questionId: string;
    name: string;
    value: any;
}

const parseObject = (input: Record<string, Record<string, any>>): ParsedObject[] =>
{
    const result: ParsedObject[] = [];

    for (const questionId in input)
    {
        const questionData = input[questionId];
        for (const name in questionData)
        {
            let value = questionData[name];

            // On mobile due to the different question type an additional record is added to the value.
            if (isMobile)
            {
                let valRec = value as Record<string, string>;
                if (valRec)
                    value = valRec[""];
            }

            result.push({ questionId, name, value });
        }
    }

    return result;
};

export function create360QuestionsAnswerFromSurveyData(data: any) : _360QuestionsAnswer
{
    let records = (data as Record<string, Record<string, any>>);
    let result = parseObject(records);

    return {answers: result};
}