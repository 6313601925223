import "./App.css";
import { ChakraProvider, theme } from "@chakra-ui/react";
import Navbar from "./Navbar";
import { useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import AccessCodePage from "./pages/access-code-page/AccessCodePage";
import Footer from "./Footer";
import LandingPage from "./pages/landing-page/LandingPage";
import TestPage from "./pages/test-page/TestPage";

function App() {
  const [navBarSubtitle, setNavBarSubtitle] = useState("");

  const setSubtitle = (text: string) => {
    setNavBarSubtitle(text);
  };

  return (
    <ChakraProvider theme={theme}>
      <div className="App">
        <div className="App-inner">
          <Navbar subTitle={navBarSubtitle} />
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<LandingPage />}></Route>
              <Route path="/access-code" element={<AccessCodePage />}></Route>
              <Route path="/test" element={<TestPage />}></Route>
            </Routes>
          </BrowserRouter>
          <Footer />
        </div>
      </div>
    </ChakraProvider>
  );
}

export default App;
