import { Card, VStack, Button, Text } from "@chakra-ui/react";

export interface ErrorComponentProps {
  errorMsg: string;
  showRetryButton: boolean;
  onRetryClick: () => void;
}

export function ErrorComponent(props: ErrorComponentProps) {
  return (
    <VStack h={"80%"} justifyContent={"center"}>
      <Card
        alignItems={"center"}
        padding={"3rem"}
        bg={"whiteAlpha.800"}
        w={"20%"}
      >
        <Text color="black.400" paddingTop="1rem">
          {props.errorMsg}
        </Text>
        {props.showRetryButton && (
          <Button
            colorScheme="teal"
            onClick={props.onRetryClick}
            flex={1}
            fontWeight="light"
            fontSize={"calc(1.2vw)"}
            mt={"1rem"}
            p={"0.5rem"}
            width={"40%"}
          >
            Retry
          </Button>
        )}
      </Card>
    </VStack>
  );
}
