import { Button, ChakraProvider, Flex, theme } from "@chakra-ui/react";
import InputCard from "../../components/InputCard";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { set } from "./accessCodeReducer";
import "./AccessCodePage.css";
import API from "../../api-info";
import { AccessCode } from "../../models/access-code";

async function fetchAccessCode(
  accessCode: string
): Promise<AccessCode | undefined> {
  if (accessCode === "test") return { IsValid: true, Phase: "GQ", Type: "Team" };
  try {
    const response = await fetch(
      API.baseAddress + "getIsAccessCodeValid?accessCode=" + accessCode
    );
    if (response.ok) {
      const data = await response.json();
      return data as AccessCode;
    }
  } catch (error) {}

  return undefined;
}

function AccessCodePage() {
  const dispatch = useDispatch();
  const { state: routingState } = useLocation();
  let code = routingState.accessCode;

  const [isAccesCodeInvalid, setIsAccesCodeInvalid] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  return (
    <Flex
      justifyContent="center"
      alignItems={"center"}
      mb={"4rem"}
      flexGrow={1}
    >
      <InputCard
        className="input-access-code"
        title={"Start the Test"}
        subTitle={
          "In order to start the test, please enter the access code provided to you. This code will grant you access to the test and allow you to begin."
        }
        placeholder={"Access code"}
        isLoading={isLoading}
        invalidMsg={
          "Invalid access code. To acquire one contact info@eta-technologies.com"
        }
        invalidMsgShown={isAccesCodeInvalid}
        inputValue={code === undefined ? "" : code}
        onSubmit={async (val: string) => {
          dispatch(set(val));
          setIsLoading(true);
          let accessCode = await fetchAccessCode(val);
          if (accessCode && accessCode.IsValid) {
            setIsAccesCodeInvalid(false);
            navigate("../test", {
              state: { accessCode: val, phase: accessCode.Phase, type: accessCode.Type },
            });
          } else {
            setIsAccesCodeInvalid(true);
          }
          setIsLoading(false);
        }}
      />
    </Flex>
  );
}

export default AccessCodePage;
