import React, { useState, useEffect } from "react";
import { Heading, Highlight, Flex, Text } from "@chakra-ui/react";

function Footer() {
  return (
    <Flex
      direction="row"
      justifyContent="center"
      bg="#131313"
      className="footer"
    >
      <Text
        color={"white"}
        fontSize="0.4em"
        style={{ fontVariant: "small-caps" }}
      >
        ETA® All rights reserved
      </Text>
      {/* <Box alignSelf='center' mr='2rem'>
                <Button colorScheme='whiteAlpha' fontSize='1.5em' variant='link' size='lg'>Sign Up</Button>
                <Button colorScheme='teal' ml='3rem' fontSize='1.5em' variant='link' size='lg'>Log in </Button>
            </Box> */}
    </Flex>
  );
}

export default Footer;
