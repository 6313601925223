import { useLocation } from "react-router-dom";
import SurveyPage, {
  QuestionData,
  QuestionDataResponse,
  SurveyAnswer,
} from "./survey/SurveyPage";
import useFetch from "../../useFetch";
import API from "../../api-info";
import post from "../../post";
import { GeneralQuestionsAnswer } from "../../models/general-questions-answer";
import GeneralQuestionsPage, {
  GeneralQuestionsDataResponse,
} from "./general-questions/GeneralQuestionsPage";
import FetchResultComponent, {
  isFetchDataAvailable,
} from "../../fetchProcessor";
import { useEffect, useState } from "react";
import _360Page from "./360/360Page";
import TestWelcomePage from "./introduction/TestWelcomePage";
import {
  _360QuestionsAnswer,
  create360QuestionsAnswerFromSurveyData,
} from "../../models/360-questions-answer";
import { AccessCodePhase, AccessCodeType } from "../../models/access-code";
import ShortBreakPage, { SlideAnimation } from "./short-break/ShortBreakPage";
import SectionIntroductionPage from "./introduction/SectionIntroductionPage";
import FinishPage from "./finish/FinishPage";
import { getNextState, getNextStateSolo } from "./TestPageFlow";
import { LoadingComponent } from "../../components/LoadingComponent";

export enum SurveyState {
  Welcome,
  GeneralQuestionsIntroduction,
  GeneralQuestions,
  Break1,
  SurveyIntroduction,
  Survey,
  Break2,
  _360Introduction,
  _360,
  Finish,
  Unknown,
}

const quotesArray = [
  {
    Quote:
      "Teams should be able to act with the same unity of purpose and focus as a well-motivated individual.",
    Name: "Bill Gates",
  },
  {
    Quote:
      "The most important thing is to keep your team as small as possible.",
    Name: "Mark Zuckerberg",
  },
  {
    Quote:
      "Great things in business are never done by one person, they’re done by a team of people.",
    Name: "Steve Jobs",
  },
  {
    Quote: "Success comes from delegating, risk-taking and having a good TEAM!",
    Name: "Richard Branson",
  },
  {
    Quote: "If you can't feed a team with two pizzas, it's too large.",
    Name: "Jeff Bezos",
  },
  {
    Quote:
      "Talent is extremely important. It’s like a sports team, the team that has the best individual player will often win, but then there’s a multiplier from how those players work together and the strategy they employ.",
    Name: "Elon Musk",
  },
  {
    Quote:
      "When you turn your team upside down and try to figure out what the culture of the team is, you take the greatest risk a team can take.",
    Name: "Mark Cuban",
  },
  {
    Quote:
      "You can design and create, and build the most wonderful place in the world. But it takes people to make the dream a reality.",
    Name: "Walt Disney",
  },
  {
    Quote:
      "Having first rate people on the team is more important than designing hierarchies and clarifying who reports to whom.",
    Name: "Warren Buffett",
  },
  {
    Quote:
      "Coming together is a beginning. Keeping together is progress. Working together is success.",
    Name: "Henry Ford",
  },
];

function getRandomNumber(min: number, max: number): number {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

function generateTwoDifferentRandomNumbers(): [number, number] {
  let length = quotesArray.length - 1;
  let num1 = getRandomNumber(0, length);
  let num2 = getRandomNumber(0, length);

  // Ensure the two numbers are different
  while (num1 === num2) {
    num2 = getRandomNumber(0, length);
  }

  return [num1, num2];
}

function TestPage() {
  let isProduction: boolean = true;
  if (!isProduction) return <TestPage_Test />;
  else return <TestPage_Production />;
}

function TestPage_Test() {
  const [surveyState, setSurveyState] = useState<SurveyState>(SurveyState._360);
  const changeSurveyState = () => {
    let nextState = getNextState(surveyState);
    setSurveyState(nextState);
  };

  const [indexes, setIndexes] = useState<[number, number]>(
    generateTwoDifferentRandomNumbers()
  );

  if (surveyState === SurveyState.Welcome)
    return <TestWelcomePageWrapper changeSurveyState={changeSurveyState} />;
  else if (surveyState === SurveyState.GeneralQuestionsIntroduction) {
    return (
      <SectionIntroductionPageWrapper_GQ
        changeSurveyState={changeSurveyState}
      />
    );
  } else if (surveyState === SurveyState.GeneralQuestions) {
    return (
      <GeneralQuestionsPageWrapper_Test changeSurveyState={changeSurveyState} />
    );
  } else if (surveyState === SurveyState.Break1) {
    let quote = quotesArray[indexes[0]];
    return (
      <ShortBreakPageWrapper
        changeSurveyState={changeSurveyState}
        quote={quote.Quote}
        name={quote.Name}
      />
    );
  } else if (surveyState === SurveyState.SurveyIntroduction) {
    return (
      <SectionIntroductionPageWrapper_Survey
        changeSurveyState={changeSurveyState}
      />
    );
  } else if (surveyState === SurveyState.Survey)
    return <SurveyPageWrapper_Test changeSurveyState={changeSurveyState} />;
  else if (surveyState === SurveyState.Break2) {
    let quote = quotesArray[indexes[1]];
    return (
      <ShortBreakPageWrapper
        changeSurveyState={changeSurveyState}
        quote={quote.Quote}
        name={quote.Name}
      />
    );
  } else if (surveyState === SurveyState._360Introduction) {
    return (
      <SectionIntroductionPageWrapper_360
        changeSurveyState={changeSurveyState}
      />
    );
  } else if (surveyState === SurveyState._360)
    return <_360PageWrapper_Test changeSurveyState={changeSurveyState} />;
  else if (surveyState === SurveyState.Finish) return <FinishPage />;
  else return <></>;
}

function TestPage_Production() {
  const { state: routingState } = useLocation();
  let phase = routingState.phase as AccessCodePhase;
  let type = routingState.type as AccessCodeType;
  let initSurveyState = SurveyState.Welcome;
  if (phase === "FINISHED") initSurveyState = SurveyState.Finish;
  if (phase === "SV" && type === "Team") initSurveyState = SurveyState.SurveyIntroduction;
  if (phase === "TS") initSurveyState = SurveyState._360Introduction;

  const [surveyState, setSurveyState] = useState<SurveyState>(initSurveyState);
  const changeSurveyState = () => {
    let nextState : SurveyState = SurveyState.Welcome;
    if (type === "Solo")
      nextState = getNextStateSolo(surveyState);
    else if (type === "Team")
      nextState = getNextState(surveyState);
    else
      throw new Error("Invalid type for next phase");

    setSurveyState(nextState);
  };

  const [indexes, setIndexes] = useState<[number, number]>(
    generateTwoDifferentRandomNumbers()
  );

  if (surveyState === SurveyState.Welcome)
    return <TestWelcomePageWrapper changeSurveyState={changeSurveyState} />;
  else if (surveyState === SurveyState.GeneralQuestionsIntroduction) {
    return (
      <SectionIntroductionPageWrapper_GQ
        changeSurveyState={changeSurveyState}
      />
    );
  } else if (surveyState === SurveyState.GeneralQuestions) {
    return (
      <GeneralQuestionsPageWrapper changeSurveyState={changeSurveyState} />
    );
  } else if (surveyState === SurveyState.Break1) {
    let quote = quotesArray[indexes[0]];
    return (
      <ShortBreakPageWrapper
        changeSurveyState={changeSurveyState}
        quote={quote.Quote}
        name={quote.Name}
      />
    );
  } else if (surveyState === SurveyState.SurveyIntroduction) {
    return (
      <SectionIntroductionPageWrapper_Survey
        changeSurveyState={changeSurveyState}
      />
    );
  } else if (surveyState === SurveyState.Survey)
    return <SurveyPageWrapper changeSurveyState={changeSurveyState} />;
  else if (surveyState === SurveyState.Break2) {
    let quote = quotesArray[indexes[1]];
    return (
      <ShortBreakPageWrapper
        changeSurveyState={changeSurveyState}
        quote={quote.Quote}
        name={quote.Name}
      />
    );
  } else if (surveyState === SurveyState._360Introduction) {
    return (
      <SectionIntroductionPageWrapper_360
        changeSurveyState={changeSurveyState}
      />
    );
  } else if (surveyState === SurveyState._360)
    return <_360PageWrapper changeSurveyState={changeSurveyState} />;
  else if (surveyState === SurveyState.Finish) return <FinishPage />;
  else return <></>;
}

interface WrapperProps {
  changeSurveyState: () => void;
}

function TestWelcomePageWrapper(props: WrapperProps) {
  return <TestWelcomePage onNextClicked={() => props.changeSurveyState()} />;
}

function SectionIntroductionPageWrapper_GQ(props: WrapperProps) {
  return (
    <SectionIntroductionPage
      header="First Section"
      text="First, we'll begin with a series of general questions about the
            team. These serve as a preliminary warm-up for the more in-depth
            inquiries to follow."
      onNextClicked={() => props.changeSurveyState()}
    />
  );
}

function SectionIntroductionPageWrapper_Survey(props: WrapperProps) {
  return (
    <SectionIntroductionPage
      header="Second Section"
      text="Now, we will move on to the central portion of the questionnaire. Please provide your responses instinctively, without overthinking the answers."
      onNextClicked={() => props.changeSurveyState()}
    />
  );
}

function SectionIntroductionPageWrapper_360(props: WrapperProps) {
  return (
    <SectionIntroductionPage
      header="Last Section"
      text="As we approach the conclusion of this test, we kindly request that you revisit certain questions that were previously covered. However, this time, we ask that you respond while adopting the perspective of your teammates."
      onNextClicked={() => props.changeSurveyState()}
    />
  );
}

function ShortBreakPageWrapper(
  props: WrapperProps & { quote: string; name: string }
) {
  const delay = (ms: any) => new Promise((res) => setTimeout(res, ms));
  const [animation, setAnimation] = useState<SlideAnimation>("scale-in-right");

  useEffect(() => {
    async function waitAndProceed() {
      await delay(5000);
      setAnimation("scale-out-left");
      await delay(750);
      props.changeSurveyState();
    }

    waitAndProceed();
  }, []);

  return (
    <ShortBreakPage
      animation={animation}
      quote={props.quote}
      name={props.name}
    />
  );
}

function SurveyPageWrapper(props: WrapperProps) {
  const { state: routingState } = useLocation();
  let response: QuestionDataResponse;
  let accessCode = routingState.accessCode;
  if (accessCode === undefined) accessCode = "";
  var config = {
    headers: { "Access-Code": accessCode },
  };

  const { data, state, result, refetch } = useFetch(
    API.baseAddress + "getQuestionnaire",
    config
  );

  const postData = async (answer: SurveyAnswer) => {
    var config = {
      headers: { "Access-Code": routingState.accessCode },
    };
    let response = await post(
      API.baseAddress + "postSurveyAnswer",
      answer,
      config
    );
    let success = response.data;
    if (success) props.changeSurveyState();
  };

  if (isFetchDataAvailable(state, result, data) && data != null) {
    response = data! as QuestionDataResponse;
    return <SurveyPage questionData={response} postData={postData} />;
  } else
    return (
      <FetchResultComponent
        state={state}
        result={result}
        onRetry={refetch}
        onInvalidClick={() => {}}
        errorMsg={"Failed to acquire the questionnaire data."}
        forbiddenMsg={"Invalid access code."}
      ></FetchResultComponent>
    );
}

function SurveyPageWrapper_Test(props: WrapperProps) {
  const postData = async (answer: SurveyAnswer) => {
    props.changeSurveyState();
  };

  return (
    <SurveyPage
      questionData={constructSurveyQuestionsForTest()}
      postData={postData}
    />
  );
}

function GeneralQuestionsPageWrapper(props: WrapperProps) {
  const { state: routingState } = useLocation();
  let accessCode = routingState.accessCode;
  if (accessCode === undefined) accessCode = "";
  var config = {
    headers: { "Access-Code": accessCode },
  };

  const postGeneralQuestionsData = async (answer: GeneralQuestionsAnswer) => {
    let response = await post(
      API.baseAddress + "postGeneralQuestionsAnswer",
      answer,
      config
    );
    let success = response.data;
    if (success) {
      props.changeSurveyState();
    }
  };

  // Ezen a ponton szétkéne szálazni a viselkedést.
  // A phasek-nek igy kellene kinéznie:
  // General Questions data load -> general questions -> General questions data post -> break1 -> ...
  // Jelenleg a data load és a data post nincs külön kezelve, ezért nincsen rá komponens.

  const { data, state, result, refetch } = useFetch(
    API.baseAddress + "getGeneralQuestionsData",
    config
  );

  let response: GeneralQuestionsDataResponse;

  if (isFetchDataAvailable(state, result, data) && data != null) {
    response = data! as GeneralQuestionsDataResponse;
    return (
      <GeneralQuestionsPage
        questionData={response}
        postData={postGeneralQuestionsData}
      />
    );
  } else
    return (
      <FetchResultComponent
        state={state}
        result={result}
        onRetry={refetch}
        onInvalidClick={() => {}}
        errorMsg={"Failed to acquire the questionnaire data."}
        forbiddenMsg={"Invalid access code."}
      ></FetchResultComponent>
    );
}

function GeneralQuestionsPageWrapper_Test(props: WrapperProps) {
  const postGeneralQuestionsData = async (answer: GeneralQuestionsAnswer) => {
    props.changeSurveyState();
  };

  let response: GeneralQuestionsDataResponse = {
    TeamMembers: ["Alice", "Bob"],
    Name: "Candice",
  };

  return (
    <GeneralQuestionsPage
      questionData={response}
      postData={postGeneralQuestionsData}
    />
  );
}

function _360PageWrapper(props: WrapperProps) {
  const { state: routingState } = useLocation();
  let response: QuestionDataResponse;
  let accessCode = routingState.accessCode;
  if (accessCode === undefined) accessCode = "";
  var config = {
    headers: { "Access-Code": accessCode },
  };

  const { data, state, result, refetch } = useFetch(
    API.baseAddress + "getThirdSectionQuestionsData",
    config
  );

  const postData = async (answer: _360QuestionsAnswer) => {
    var config = {
      headers: { "Access-Code": routingState.accessCode },
    };
    let response = await post(
      API.baseAddress + "postThirdSectionQuestionsAnswer",
      answer,
      config
    );
    let success = response.data;
    if (success) props.changeSurveyState();
  };

  if (isFetchDataAvailable(state, result, data) && data != null) {
    response = data! as QuestionDataResponse;
    return <_360Page questionData={response} postData={postData} />;
  } else
    return (
      <FetchResultComponent
        state={state}
        result={result}
        onRetry={refetch}
        onInvalidClick={() => {}}
        errorMsg={"Failed to acquire the questionnaire data."}
        forbiddenMsg={"Invalid access code."}
      ></FetchResultComponent>
    );
}

function _360PageWrapper_Test(props: WrapperProps) {
  return (
    <_360Page
      questionData={constructSurveyQuestionsForTest()}
      postData={(data) => {
        create360QuestionsAnswerFromSurveyData(data);
        props.changeSurveyState();
      }}
    />
  );
}

function constructSurveyQuestionsForTest() {
  let response: QuestionDataResponse;
  let questionData: QuestionData[] = [];
  response = {
    Questions: questionData.slice(0, 3),
    TeamMembers: createTeam(2),
    CandidateName: "c",
  };

  return response;
}

function createTeam(size: number) {
  return ["Alice", "Bob", "Cecil", "David"].slice(0, size);
}

export default TestPage;
