import { useEffect, useState } from "react";
import axios, { AxiosRequestConfig } from "axios";

export enum APICallState
{
    Loading,
    Loaded,
}

export enum APICallResult
{
    Unknown,
    Ok,
    Error,
    Forbidden
}

function useFetch(url: string, config: AxiosRequestConfig, skipFirstCall = false)
{
    const [data, setData] = useState(null);
    const [state, setState] = useState(APICallState.Loading);
    const [result, setResult] = useState(APICallResult.Unknown);

    useEffect(() =>
    {
        if (skipFirstCall)
            return;

        refetch();
    }, [url]);

    const refetch = () =>
    {
        setResult(APICallResult.Unknown);
        setState(APICallState.Loading);
        axios
            .get(url, config)
            .then((response) =>
            {
                setData(response.data);
                setResult(APICallResult.Ok);
            })
            .catch((err) =>
            {
                let status: number = err.response.status;
                if (status === 403)
                    setResult(APICallResult.Forbidden);
                else
                    setResult(APICallResult.Error);
            })
            .finally(() =>
            {
                setState(APICallState.Loaded);
            });
    };

    return { data, state, result, refetch };
}

export default useFetch;