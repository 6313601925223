import { ErrorComponent } from "./components/ErrorComponent";
import { LoadingComponent } from "./components/LoadingComponent";
import { APICallResult, APICallState } from "./useFetch";

export function isFetchDataAvailable(
  state: APICallState,
  result: APICallResult,
  data: any
) {
  return (
    state === APICallState.Loaded && result === APICallResult.Ok && data != null
  );
}

export interface FetchResultComponentProps {
  state: APICallState;
  result: APICallResult;
  errorMsg: string;
  forbiddenMsg: string;
  onRetry: () => void;
  onInvalidClick: () => void;
}

function FetchResultComponent(props: FetchResultComponentProps) {
  if (props.state === APICallState.Loading) {
    return <LoadingComponent />;
  }
  if (
    props.result === APICallResult.Error ||
    props.result === APICallResult.Unknown
  )
    return (
      <ErrorComponent
        errorMsg={props.errorMsg}
        showRetryButton
        onRetryClick={props.onRetry}
      />
    );
  if (props.result === APICallResult.Forbidden)
    return (
      <ErrorComponent
        errorMsg={props.forbiddenMsg}
        showRetryButton={false}
        onRetryClick={function (): void {
          throw new Error("Function not implemented.");
        }}
      />
    );
  else return <></>;
}

export default FetchResultComponent;
