import {
  Box,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CircularProgress,
  Flex,
  Heading,
  Progress,
  Text,
} from "@chakra-ui/react";
import { isMobile } from "react-device-detect";
import "./ShortBreakPage.css";

export type SlideAnimation =
  | "slide-in-right"
  | "scale-in-right"
  | "slide-out-left"
  | "scale-out-left";
export interface ShortBreakPageProps {
  animation: SlideAnimation;
  quote: string;
  name: string;
}

function ShortBreakPage(props: ShortBreakPageProps) {
  return (
    <Flex
      className={props.animation}
      flexGrow={1}
      justifyContent={"center"}
      margin={isMobile ? "1rem" : "0rem"}
    >
      <Card
        boxShadow={"none"}
        bg={"#35373f"}
        justifySelf={"center"}
        alignSelf={"center"}
        height={"50%"}
        marginBottom={isMobile ? "0" : "4rem"}
        width={isMobile ? "100" : "70%"}
        borderLeft={"4px"}
        borderLeftColor={"#f56565"}
      >
        <CardHeader>
          <Flex direction={"row"} justifyContent={"space-between"}>
            <Heading
              color="#3acad3"
              textAlign={"center"}
              size={"lg"}
              fontWeight={"semibold"}
              style={{
                fontVariant: "small-caps",
              }}
            ></Heading>
            <CircularProgress
              isIndeterminate
              color="#f56565"
              size={isMobile ? "30px" : "40px"}
              thickness={"10px"}
            />
          </Flex>
        </CardHeader>
        <CardBody marginTop={isMobile ? "-1.5rem" : "-3rem"}>
          <>
            <Text
              fontSize={isMobile ? "lg" : "4xl"}
              color="#bbbbbb"
              textAlign={"center"}
            >
              “{props.quote}”
            </Text>
            <Text
              marginTop={"1rem"}
              fontSize={isMobile ? "md" : "2xl"}
              color="gray"
              textAlign={"end"}
            >
              {props.name}
            </Text>
          </>
        </CardBody>
      </Card>
    </Flex>
  );
}

export default ShortBreakPage;
