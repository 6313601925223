import * as React from "react";
import {
  Container,
  Box,
  FormControl,
  Input,
  Stack,
  Button,
  Heading,
  VStack,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { useState } from "react";
import { useCheckMobileScreen } from "../useCheckMobileScreen";

export interface PublicProps {
  className: string;
  title: string;
  subTitle: string;
  placeholder: string;
  invalidMsg: string;
  onSubmit(input: string): void;
  invalidMsgShown: boolean;
  isLoading: boolean;
  inputValue: string;
}

const InputCard = (props: PublicProps) => {
  const [inputValue, setInputValue] = useState(props.inputValue);
  const isMobile = useCheckMobileScreen();

  function changeValue(event: any) {
    setInputValue(event.target.value);
  }

  return (
    <Container
      maxW="5x1"
      className={props.className}
      margin={isMobile ? "0rem 0.5rem" : -1}
    >
      <Stack spacing={4} maxW={{ base: "30rem", sm: "30rem" }} margin="0 auto">
        <Box pos="relative">
          <VStack
            as="form"
            pos="relative"
            spacing={6}
            p={6}
            bg={useColorModeValue("white", "gray.700")}
            rounded="lg"
            boxShadow="lg"
          >
            <Heading fontSize={{ base: "xl", sm: "3xl" }} marginBottom={0}>
              {props.title}
            </Heading>
            <Text marginTop={0} fontSize={{ base: "sm", sm: "md" }}>
              {props.subTitle}
            </Text>
            <FormControl>
              <Input
                errorBorderColor="red.500"
                isInvalid={props.invalidMsgShown && !props.isLoading}
                type="text"
                disabled={props.isLoading}
                placeholder={props.placeholder}
                rounded="md"
                value={inputValue}
                onChange={changeValue}
              />
            </FormControl>
            <Button
              colorScheme="teal"
              color="white"
              _hover={{
                bg: "blue.500",
              }}
              rounded="md"
              w="100%"
              isLoading={props.isLoading}
              onClick={() => props.onSubmit(inputValue)}
              disabled={inputValue.length === 0}
            >
              OK
            </Button>
          </VStack>
          <Text paddingTop="0.1rem" fontSize={"xs"} hidden={!props.isLoading}>
            Validating access code...
          </Text>
          {!props.isLoading && props.invalidMsgShown && (
            <Text fontSize="xs" paddingTop="0.1rem" color="red.500">
              {props.invalidMsg}
            </Text>
          )}
        </Box>
      </Stack>
    </Container>
  );
};

export default InputCard;
