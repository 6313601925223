import {
  Box,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Flex,
  Heading,
  Link,
  Text,
} from "@chakra-ui/react";
import { isMobile } from "react-device-detect";

export interface TestWelcomePageProps {
  onNextClicked: () => void;
}
function TestWelcomePage(props: TestWelcomePageProps) {
  return (
    <Flex
      flexGrow={1}
      justifyContent={"center"}
      margin={isMobile ? "1rem" : "0rem"}
    >
      <Card
        boxShadow={"none"}
        bg={"#35373f"}
        justifySelf={"center"}
        height={"50%"}
        marginTop={isMobile ? "0" : "4rem"}
        width={isMobile ? "100" : "70%"}
        borderLeft={"4px"}
        borderLeftColor={"#f56565"}
      >
        <CardHeader>
          <Box alignSelf="start">
            <Heading
              mt={"1rem"}
              color="#3acad3"
              textAlign={"center"}
              size={"lg"}
              fontWeight={"semibold"}
              style={{
                fontVariant: "small-caps",
              }}
            >
              Welcome to ETA Technologies!
            </Heading>
          </Box>
        </CardHeader>
        <CardBody>
          <Text
            marginTop={"1rem"}
            fontSize={isMobile ? "md" : "xl"}
            fontFamily={"sans-serif"}
            color="#bbbbbb"
            textAlign={"center"}
            whiteSpace={"pre-line"}
          >
            They say that investors invest in people. However, there has been a
            lack of an accurate, quantitative methodology to truly understand
            and measure people in the investment decision-making process. We've
            stepped in to fill this gap.{"\n\n"} Over the next 10-15 minutes,
            you will be presented with a series of questions designed to gain
            insight into you as an entrepreneur. Your honest and precise
            responses are crucial. {"\n\n"}Should you have any inquiries or
            encounter any difficulties, please do not hesitate to{" "}
            <Link href="mailto:info@eta-technologies.com" color={"teal.500"}>
              contact us
            </Link>
            .{"\n\n"}
            Enjoy!
          </Text>
        </CardBody>
        <CardFooter justifyContent={"center"}>
          <Button
            justifyContent={"center"}
            padding={"0rem 2rem"}
            size={isMobile ? "md" : "lg"}
            fontSize={isMobile ? "md" : "lg"}
            onClick={props.onNextClicked}
          >
            Start the test
          </Button>
        </CardFooter>
      </Card>
    </Flex>
  );
}

export default TestWelcomePage;
