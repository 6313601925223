import React, { useState, useEffect } from "react";
import {
  Heading,
  Highlight,
  Flex,
  Icon,
  Box,
  Stack,
  Button,
  Image,
} from "@chakra-ui/react";
import { SiReacttable } from "react-icons/si";
import { FaGgCircle } from "react-icons/fa";
import { useCheckMobileScreen } from "./useCheckMobileScreen";

export interface Props {
  subTitle: string;
}

function Navbar(props: Props) {
  const isMobile = useCheckMobileScreen();

  return (
    <Box lineHeight="tall" p={4}>
      <Flex
        direction="row"
        justifyContent="space-between"
        alignItems={"center"}
        paddingTop={"0.2rem"}
        paddingBottom={"0.2rem"}
        marginLeft={isMobile ? "0.15rem" : "0.75rem"}
      >
        <Flex direction={"row"} alignItems={"center"}>
          <Image src="/eta-ver2-white.png" width={isMobile ? "35%" : "20%"} />
          {/* <Box alignSelf='center' mr='2rem'>
                <Button colorScheme='whiteAlpha' fontSize='1.5em' variant='link' size='lg'>Sign Up</Button>
                <Button colorScheme='teal' ml='3rem' fontSize='1.5em' variant='link' size='lg'>Log in </Button>
            </Box> */}
        </Flex>
      </Flex>
    </Box>
  );
}

export default Navbar;
